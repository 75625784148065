/* Browser scroll to via ID
  https://github.com/gatsbyjs/gatsby/issues/8413
*/

const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) return window.scrollTo(0, el.offsetTop - 20)
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    console.log('importing intersection observer')
    await import(`intersection-observer`)
  }
}
